import React from 'react'
import { Col, Card } from 'react-bootstrap'
import { useSearchParams } from '../../libs/hooks'

const ProviderCardItem = ({
  heading,
  button,
  imageUrl,
  richText,
  subtitle,
  buttonLink,
  orgId,
  payerLink,
}) => {
  const url = useSearchParams(buttonLink, orgId, payerLink)

  return (
    <Col sm={12} md={6} className="my-3">
      <Card className="mx-auto provider-card-item text-left p-3 d-flex flex-column h-100">
        <Card.Body>
          {imageUrl && <Card.Img variant="top" src={imageUrl}></Card.Img>}
          <Card.Title className="display-4 provider-card-title">
            <strong>{heading}</strong>
          </Card.Title>
          <Card.Text className="provider-card-subtitle">{subtitle}</Card.Text>
          <div className="provider-card-description">{richText}</div>
          {button && (
            <a href={url} className="card-btn my-2 site-btn cta">
              {button}
            </a>
          )}
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ProviderCardItem
