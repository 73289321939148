import React from 'react'
import { Col, Row } from 'react-bootstrap'
import parse from 'html-react-parser'
import { MappedImage } from '../Image'
import {
  textStyle,
  buttonStyle,
  backgroundStyle,
  containerImageCover,
} from '../../utils'
import { GTM } from '../../constants'
import StandardCard from '../CardModule/StandardCard'
import ProviderCardItem from '../CardModule/ProviderCard'
import SingleConditionCard from '../CardModule/SingleConditionCard'
import { useSearchParams } from '../../libs/hooks'

export const FullWidthCTA = ({
  backgroundImage,
  moduleWidth,
  moduleAlignment,
  heading,
  subHeading,
  description,
  disclaimer,
  htmlText,
  featuredImage,
  buttonLink,
  buttonText,
  hyperlink,
  hyperlinkText,
  payerLink,
  orgId,
  style,
  hero,
  subModules,
}) => {
  const url = useSearchParams(buttonLink, orgId, payerLink)

  return (
    <Col
      sm={12}
      md={moduleWidth}
      className={`bottom-cta module ${hero && 'hero'}`}
      css={[
        containerImageCover(backgroundImage?.file.url),
        backgroundStyle(style?.backgroundColor),
      ]}
    >
      {moduleAlignment && (
        <div
          className={`module-wrapper d-flex w-100 mx-auto justify-content-${moduleAlignment}`}
        >
          <Row
            className={`p-3 module-inner ${
              moduleWidth <= 6 ? 'half-width' : 'full-width'
            }`}
          >
            <div className="cta-content-wrapper mx-auto text-center">
              {heading && (
                <div
                  className="module-heading my-4"
                  css={textStyle(style?.textColor)}
                >
                  {parse(heading)}
                </div>
              )}
              {subHeading && (
                <div
                  className="module-subheading my-4"
                  css={textStyle(style?.textColor)}
                >
                  {parse(subHeading)}
                </div>
              )}
              <div className="cta-img">
                {featuredImage && (
                  <div className="cta-img-wrapper">
                    <MappedImage
                      className="m-auto my-3 text-center"
                      image={featuredImage}
                    />
                  </div>
                )}
                {htmlText && (
                  <div className="module-html-text">
                    {parse(htmlText.htmlText)}
                  </div>
                )}
              </div>
              {subModules &&
                subModules.map((item, i) => (
                  <React.Fragment key={i}>
                    {item.containerType === 'Card Item' ? (
                      item.cardType === 'Single-Condition Card' ? (
                        <SingleConditionCard
                          {...{ ...item, orgId, payerLink }}
                        />
                      ) : (
                        <StandardCard {...{ ...item, orgId, payerLink }} />
                      )
                    ) : (
                      <ProviderCardItem {...{ ...item, orgId, payerLink }} />
                    )}
                    {/* TODO: add other conditionals for containerType as needed */}
                  </React.Fragment>
                ))}
              <div className="disclaimer-outer">
                <p>
                  {disclaimer && true
                    ? description && (
                        <div className="disclaimer">
                          {description.description}
                        </div>
                      )
                    : description && (
                        <>
                          <div className="description">
                            {parse(description.description)}
                          </div>
                        </>
                      )}
                </p>
              </div>
              {buttonText && buttonLink && (
                <div className="btn-wrapper my-4">
                  <a
                    href={url}
                    aria-label={`${buttonText}`}
                    id={orgId + '-' + GTM.TAKE_QUIZ}
                    className="my-4 site-btn"
                    css={buttonStyle(
                      style?.buttonColor,
                      style?.buttonTextColor
                    )}
                  >
                    {buttonText}
                  </a>
                </div>
              )}
              {hyperlink && hyperlinkText && (
                <div className="hyperlink-wrapper">
                  <span>&nbsp;</span>
                  <a href={`/${hyperlink}`} className="hyperlink site-btn">
                    {hyperlinkText}
                  </a>
                </div>
              )}
            </div>
          </Row>
        </div>
      )}
    </Col>
  )
}

export const mapFullWidthCTAProps = props => props
