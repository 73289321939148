import React from 'react'
import { Col, Card } from 'react-bootstrap'
import parse from 'html-react-parser'
import { useSearchParams } from '../../libs/hooks'

const CardFooter = ({ button, url }) => {
  return (
    <div className="button-wrapper-outer">
      <div className="button-wrapper-inner">
        {button && (
          <a href={url} className="card-btn my-2 site-btn cta">
            {button}
          </a>
        )}
      </div>
    </div>
  )
}

const StandardCard = ({
  button,
  heading,
  image,
  imageUrl,
  subtitle,
  buttonLink,
  textList,
  orgId,
  payerLink,
}) => {
  const url = useSearchParams(buttonLink, orgId, payerLink)

  return (
    <Col className="my-3 site-card-outer">
      <Card className="mx-auto site-card text-white text-center p-3 d-flex flex-column h-100">
        <Card.Body>
          {imageUrl && (
            <Card.Img variant="top" src={imageUrl} alt={image.title}></Card.Img>
          )}
          {heading && (
            <Card.Title className="display-4">
              <h2 className="card-heading">{parse(heading)}</h2>
            </Card.Title>
          )}
          {subtitle && (
            <div className="card-text-wrapper d-flex flex-column">
              <Card.Text className="py-4">{parse(subtitle)}</Card.Text>
            </div>
          )}
          {textList && (
            <ul className="text-list-wrapper">
              {textList?.map((text, i) => (
                <li className={`text-list-item-${i}`} key={`${text}-${i}`}>
                  {text}
                </li>
              ))}
            </ul>
          )}
        </Card.Body>
        <CardFooter button={button} url={url} />
      </Card>
    </Col>
  )
}

export default StandardCard
